.App {
  text-align: center;
}

a {
  margin: 10px;
}

.Mail {
  text-align: auto;
  width: 30%;
}

header {
  background-color: #6690e4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(14, 36, 1);
}

.navbar-nav {
  text-align: center;
  background-color: #f3ececef;

}

.Form {
  width: 20%;
  margin-bottom: -3.5;
}

.h1 {
  width: auto;
  text-align: center;
}

.sr-only {
  display: none;
}
