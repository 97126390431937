.header{
    background-color: #2940D3;
    color: #FFEDA3;
    max-width: 100%;
    padding: 50px;

    h2{
        margin: 0;
        padding: 0;
        font-size: 4em;
        text-align: center;

    }
}
