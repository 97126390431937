.container {
  display: flex;
}

.left-container,
.right-container {
  flex: 1;
  /* height: 100%; */
  border: 1px solid #000;
}

.middle-container {
  flex: 2; /* 2 veces el ancho de left-container o right-container */
  /* display: flex; */
  /* overflow-x: auto;  */
}

.middle-container2 {
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  gap: 3px; /* Espacio entre los nombres */
  overflow-x: auto;
  margin-top: -165px;

}

.elemento {
  padding: 5px; /* Espaciado interno de los nombres */
  border: 1px solid black; /* Borde para los nombres */
  width: auto;
  height: 50px;
  background-color: #ccc;
  margin-right: 10px; /* Espacio entre elementos */
}
